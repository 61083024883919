<template>
    <authorize-layout>
        <v-card
            class="mx-auto my-auto pa-5"
            style="border-radius: 25px;"
            width="560"
            height="483"
            outlined
            elevation="5"
            :loading="loading"
        >
            <v-card-title class="justify-center auth-title" v-text="'Log in'"/>
            <v-card-text>
                <v-container class="pa-5">
                    <auth-with provider="facebook"/>
                    <auth-with provider="google"/>
                    <v-form>
                        <v-text-field
                            v-model="email"
                            label="email"
                            :error-messages="errors.email"
                            :disabled="loading"/>
                        <v-text-field
                            v-model="password"
                            label="password"
                            :error-messages="errors.password"
                            :append-icon="password ? value ? 'mdi-eye' : 'mdi-eye-off' : 'mdi-eye-off'"
                            @click:append="() => (value = !value)"
                            :type="value ? 'password' : 'text'"
                            :disabled="loading"/>
                        <router-link
                            :to="{name: 'forgot-password'}"
                            v-text="'Forgot password?'"
                            class="text-style auth-text auth-underline"/>
                    </v-form>
                </v-container>
            </v-card-text>
            <v-card-actions class="text-center justify-space-around">
                <div>
                    <div>
                        <v-btn
                            class="auth-title auth-btn"
                            :disabled="!password || !email"
                            @click="handleLogin"
                            v-text="'Log in'"/>
                    </div>
                    <p class="mt-15 text-style auth-text">
                        Don’t have an account yet?
                        <router-link
                            :to="{name: 'pricing'}"
                            v-text="'Sign up'"
                            class="auth-underline"/>
                    </p>
                </div>
            </v-card-actions>
        </v-card>
    </authorize-layout>
</template>

<script>
    import {mapActions} from "vuex";
    import AuthorizeLayout from '../layouts/AuthorizeLayout.vue';
    import AuthWith from '../components/AuthWith';

    export default {
        components: {
            AuthorizeLayout,
            AuthWith
        },
        name: 'Login',
        data() {
            return {
                email: '',
                password: '',
                value: 'true',
                errors: {},
                loading: false
            }
        },
        mounted() {
            // if query param is isset, then page load with OAuth token
            this.loading = !!this.$route.query.t
        },
        methods: {
            handleLogin() {
                this.loading = true;
                const {email, password} = this;

                this.login({ email, password })
                    .then(async (res) => {
                        await this.$store.dispatch('myFiles/fetchFiles');
                        await this.$store.dispatch('getAuthUser')
                        await this.$router.push(res.redirect_link);
                    })
                    .catch(e => this.errors = e.response?.data?.errors)
                    .finally(() => this.loading = false)
            },

            ...mapActions(['login']),
        }
    }
</script>

<style scoped lang="scss">

.row {
    margin: 0 !important;
}

.col {
    padding: 0 !important;
}

</style>
